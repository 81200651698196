/* css for pagination starts here */

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
}

.pagination .page {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #999999;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
  border: 1px solid transparent;
}

.pagination .previous,
.pagination .next {
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #999999;
}

.pagination .previous {
  font-weight: bold;
  color: #007945;
  @apply font-bold;
}

.pagination .next {
  font-weight: bold;
  color: #007945;
  @apply font-bold;
}

.pagination .active {
  font-weight: bold;
  color: #007945;
}

.pagination .page:hover,
.pagination .previous:hover,
.pagination .next:hover {
  background-color: #f0f0f0;
  color: #007945;
}

.pagination .disabled {
  pointer-events: none;
  opacity: 0.5;
  color: #999999;
}

.previous:disabled,
.next:disabled {
  opacity: 0.5;
}

/* Ensure the Entire Area is Clickable */
.pagination .page a,
.pagination .page span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

/* css for pagination ends here */

.truncate-28ch {
  max-width: 28ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Toggle checkbox styling */
.toggle-checkbox {
  appearance: none;
  width: 36px;
  height: 18px;
  background: #ccc;
  border-radius: 9999px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.toggle-checkbox:checked {
  background: #28a745;
}

.toggle-checkbox:checked::before {
  transform: translateX(18px);
}

.toggle-checkbox::before {
  content: '';
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.2s;
}

/* css to override date calendar icon to appear in the front */
input[type='date'] {
  position: relative;
  padding-left: 2.5rem;
}

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0.75rem; /* Adjust the position to the left */
  top: 50%;
  transform: translateY(-50%);
  width: 150px;
  cursor: pointer;
}
